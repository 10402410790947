import { useRecordContext, useTranslate } from "react-admin"

import { Typography } from "@mui/material"

import type { JobSeeker } from "rikeimatch-graphql"
import type React from "react"

const JobSeekerPrefectureField = ({
  source,
}: {
  source: keyof JobSeeker
}): React.ReactNode => {
  const originalRecord = useRecordContext<JobSeeker>() || {}
  const t = useTranslate()

  return (
    <Typography component="span" variant="body2">
      {originalRecord[source]
        ? t(`resources.prefecturesForProfile.${originalRecord[source] as string}`)
        : "未入力"}
    </Typography>
  )
}

JobSeekerPrefectureField.defaultProps = {
  addLabel: true,
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobSeekerPrefectureField
