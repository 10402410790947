import type { FC } from "react"
import { useRecordContext } from "react-admin"
import type { UseRecordContextParams } from "react-admin"

import { Typography } from "@mui/material"
import { i18n } from "common-utils"

import type { JobSeeker } from "rikeimatch-graphql"

const EducationGradeField: FC<
  UseRecordContextParams<JobSeeker> & {
    source: string
  }
> = () => {
  const { educationHistory } = useRecordContext<JobSeeker>() || {}
  const gradeText = educationHistory ? i18n.grades[educationHistory.grade] : ""

  return (
    <Typography component="span" variant="body2">
      {gradeText}
    </Typography>
  )
}

EducationGradeField.defaultProps = {
  addLabel: true,
}

// biome-ignore lint/style/noDefaultExport: ...
export default EducationGradeField
