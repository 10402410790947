import { Alert } from "@mui/material"

const ErbVarsHelpText = () => (
  <Alert severity="info" sx={{ marginBottom: "1rem" }}>
    利用できる変数
    <ul>
      <li>
        <code>$FULL_NAME</code> : 名前（姓名）
      </li>
    </ul>
  </Alert>
)

// biome-ignore lint/style/noDefaultExport: ...
export default ErbVarsHelpText
