import type React from "react"
import {
  ArrayField,
  Create,
  Datagrid,
  DateInput,
  Edit,
  EditBase,
  EditButton,
  EmailField,
  FunctionField,
  ImageField,
  NumberField,
  NumberInput,
  ReferenceManyField,
  Show,
  ShowBase,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  UrlField,
  email,
  required,
  useRecordContext,
} from "react-admin"

import { Box, Divider, Grid, Typography } from "@mui/material"
import { CompanyLocationsInput } from "common-components-ra"
import { ISODateTimeToDateString } from "common-utils"

import { ButtonLink, ToolbarWithoutDelete } from "~/components/molecules"
import { jobSeekerAppHost } from "~/config"

import BusinessCategoriesInput from "../molecules/BusinessCategoriesInput"

import { useParams } from "react-router-dom"
import type { Company, CompanyStaff } from "rikeimatch-graphql"

const CompanyTitle = () => {
  const { name } = useRecordContext() || {}

  return <span>{name}</span>
}

const PreviewLink = () => {
  const { id } = useRecordContext() || {}

  return <ButtonLink href={`${jobSeekerAppHost}/company/${id}`}>学生ページで見る</ButtonLink>
}

const transformForCompanies = (company: Company) => {
  const { id, locations, businessCategories } = company

  return {
    id,
    locations,
    businessCategories,
  }
}

const transformForCompanyPlans = (company: Company) => {
  const { id, scoutCount, likeCount, expiresOn } = company

  return { id, scoutCount, likeCount, expiresOn }
}

const CompanyEdit: React.FC = () => {
  const validateEmail = [email(), required()]
  const { id } = useParams<{ id: string }>()

  const transformCompanyStaff = (companyStaff: CompanyStaff) => {
    return {
      ...companyStaff,
      companyId: id,
    }
  }
  return (
    <Box sx={{ mt: 2, mb: 20 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        企業情報・編集
      </Typography>
      <Show title={<CompanyTitle />} resource="companies" actions={false}>
        <SimpleShowLayout spacing={3}>
          <TextField source="name" />
          <EmailField source="email" />
          <FunctionField
            label="resources.companies.fields.foundedOn"
            render={(company: Company) => (
              <span>{ISODateTimeToDateString(company.foundedOn, true)}</span>
            )}
          />
          <NumberField source="employeesNumber" />
          <ImageField
            source="logo"
            sx={{
              "& .RaImageField-image": {
                maxHeight: "300px",
                width: "100%",
                objectFit: "contain",
              },
            }}
          />
          <ImageField
            source="coverImage"
            sx={{
              "& .RaImageField-image": {
                maxHeight: "300px",
                width: "100%",
                objectFit: "contain",
              },
            }}
          />
          <UrlField source="homepage" />
          <TextField source="headquartersAddress" />
          <TextField source="industry" />
          <TextField fullWidth source="businessDescription" />
          <TextField fullWidth source="corporatePhilosophy" />
          <TextField fullWidth source="offices" />

          <EditBase
            resource="companies"
            transform={transformForCompanies}
            spacing={3}
            mutationOptions={{ meta: { resource: "companies" } }}
          >
            <Divider />
            <Typography>※ ここから編集可能</Typography>
            <SimpleForm>
              <CompanyLocationsInput />
              <BusinessCategoriesInput />
            </SimpleForm>
          </EditBase>
        </SimpleShowLayout>
      </Show>

      <Divider sx={{ my: 5 }} />

      <Typography variant="h6" sx={{ mb: 2 }}>
        プラン設定
      </Typography>
      <Edit
        title=" "
        transform={transformForCompanyPlans}
        resource="companies"
        mutationOptions={{ meta: { resource: "companyPlans" } }}
      >
        <SimpleForm
          toolbar={
            <ToolbarWithoutDelete>
              <PreviewLink />
            </ToolbarWithoutDelete>
          }
        >
          <NumberInput source="likeCount" />
          <NumberInput source="scoutCount" />
          <DateInput source="expiresOn" />
        </SimpleForm>
      </Edit>

      <Divider sx={{ my: 5 }} />

      <Typography variant="h6" sx={{ mb: 2 }}>
        企業アカウント追加
      </Typography>
      <Create
        title=""
        transform={transformCompanyStaff}
        resource="companyStaffs"
        mutationOptions={{ meta: { resource: "companyStaffs" } }}
        redirect={false}
      >
        <SimpleForm>
          {/* domainMessagesのトランスレーションが効かないため、labelで直接指定する */}
          <TextInput label="メールアドレス" source="email" validate={validateEmail} />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextInput label="姓" source="lastName" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextInput label="名" source="firstName" validate={required()} fullWidth />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
      <Show>
        <SimpleShowLayout>
          <ArrayField label="企業アカウント一覧" source="companyStaffs">
            <Datagrid>
              <TextField label="メールアドレス" source="email" />
              <TextField label="姓" source="lastName" />
              <TextField label="名" source="firstName" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Show>

      <Divider sx={{ my: 5 }} />

      <Typography variant="h6" sx={{ mb: 2 }}>
        求人一覧
      </Typography>
      <ShowBase resource="companies">
        <ReferenceManyField reference="jobs" target="companyId">
          <Datagrid rowClick="edit">
            <TextField source="title" />
            <TextField source="status" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </ShowBase>
    </Box>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default CompanyEdit
