import { SelectArrayInput, useRecordContext } from "react-admin"

import type { Company } from "rikeimatch-graphql"

const BUSINESS_CATEGORIES = [
  "製造業",
  "自社開発",
  "受託開発",
  "技術派遣・SES",
  "ITベンダー",
  "コンサル",
  "プラントエンジニアリング",
  "設備工事",
  "教育",
  "鉄道",
]

const BusinessCategoriesInput = () => {
  const { businessCategories } = useRecordContext<Company>()

  const choices = BUSINESS_CATEGORIES.map((category) => ({
    id: category,
    name: category,
  }))

  return (
    <SelectArrayInput
      source="businessCategories"
      alwaysOn
      label="resources.companies.fields.businessCategories"
      sx={{ minWidth: 300 }}
      defaultValue={businessCategories}
      choices={choices}
    />
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default BusinessCategoriesInput
