import { TextField, useRecordContext, useTranslate } from "react-admin"

import { domainMessages } from "common-utils"

import type { Job } from "rikeimatch-graphql"
import type { ReactNode } from "react"

const JobStatusField = ({
  source = "status",
}: {
  source: "status"
}): ReactNode => {
  const t = useTranslate()
  const record = useRecordContext<Job>()

  return <span>{t(`resources.jobs.statuses.${record[source]}`)}</span>
}

JobStatusField.defaultProps = {
  addLabel: true,
}

const JobStatusFieldForJobList = (): ReactNode => {
  const t = useTranslate()
  const originalRecord = useRecordContext<Job>() || {}
  const translatedRecord = {
    id: originalRecord.id,
    status: t(`resources.jobs.statuses.${originalRecord.status || ""}`, {
      _: "-",
    }),
  }

  return <TextField source="status" record={translatedRecord} />
}

JobStatusFieldForJobList.defaultProps = {
  label: domainMessages.resources.jobs.fields.status,
  addLabel: true,
}

export { JobStatusField, JobStatusFieldForJobList }
