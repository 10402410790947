import React from "react"

import { Box, Stack } from "@mui/material"

import useChatGptMessages from "~/hooks/useChatGptMessages"

import ChatGptThreadListMessages from "../organisms/ChatGptThreadListMessages"
import ChatGptThreadListThreadsMenu from "../organisms/ChatGptThreadListThreadsMenu"

const ChatGptThreadList = () => {
  const [threadId, setThreadId] = React.useState<string | undefined>(undefined)
  const { chatGptMessages } = useChatGptMessages({
    threadId,
  })

  return (
    <Stack direction="row" sx={{ height: "100%" }}>
      <ChatGptThreadListThreadsMenu handleSelectThread={(id: string) => setThreadId(id)} />
      {chatGptMessages ? (
        <ChatGptThreadListMessages messages={chatGptMessages} />
      ) : (
        <Box sx={{ m: 4 }}>メッセージがありません。</Box>
      )}
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ChatGptThreadList
