import { Typography } from "@mui/material"

const InterviewTemplateListWrapCell = ({ value }: { value: string }) => (
  <Typography
    sx={{
      whiteSpace: "pre-wrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      fontSize: "small",
    }}
  >
    {value}
  </Typography>
)

// biome-ignore lint/style/noDefaultExport: ...
export default InterviewTemplateListWrapCell
