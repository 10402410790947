import type React from "react"
import {
  BooleanField,
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  SearchInput,
  TextField,
  TopToolbar,
} from "react-admin"

import { Link } from "@mui/material"
import { JOB_SEEKER_DEFAULT_SORT_BY } from "common-components-ra"

import { jobSeekerAppHost } from "~/config"

import type { JobSeekerPrivacySetting } from "rikeimatch-graphql"

const jobSeekerFilter = [<SearchInput key="freeSearch" source="q" alwaysOn />]

const Actions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

const JobSeekerPrivacySettingsList: React.FC = () => (
  <List
    resource="jobSeekerPrivacySettings"
    actions={<Actions />}
    filters={jobSeekerFilter}
    // @ts-expect-error
    sort={JOB_SEEKER_DEFAULT_SORT_BY}
  >
    <Datagrid rowClick="show">
      <TextField
        source="jobSeeker.fullName"
        label="resources.jobSeekers.fields.fullName"
        sx={{ wordBreak: "keep-all" }}
      />

      {/* 以下は admin だけのラベル表示なので domainMessages で翻訳しない */}
      <BooleanField source="allowProfilePublication" label="公開" />
      <BooleanField source="allowProfileAccessByCompanies" label="企業公開" />
      <BooleanField source="allowEmailPublication" label="Email公開" />

      <TextField source="jobSeeker.bio" label="resources.jobSeekers.fields.bio" />
      <TextField
        source="jobSeeker.careerInterests"
        label="resources.jobSeekers.fields.careerInterests"
      />
      <FunctionField
        label="URL"
        render={(privacySetting: JobSeekerPrivacySetting) => (
          <Link
            href={`${jobSeekerAppHost}/rikelink/${privacySetting.jobSeeker.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            URL
          </Link>
        )}
      />
    </Datagrid>
  </List>
)

// biome-ignore lint/style/noDefaultExport: ...
export default JobSeekerPrivacySettingsList
