import type React from "react"
import type { Identifier, RaRecord } from "react-admin"
import { Datagrid, Link, List, TextField, useRedirect } from "react-admin"

const FeatureTopicList: React.FC = () => {
  const redirect = useRedirect()

  const handleRowClick = (id: Identifier, _basePath: string, _record: RaRecord): false => {
    redirect("edit", "featureTopics", id)

    return false
  }

  // 一旦 pagination を無効にしたため、10件以上のデータがある場合は表示されない
  return (
    <List resource="allFeatureTopics" title="特集ページ">
      <Datagrid rowClick={handleRowClick}>
        <TextField source="slug" />
        <TextField source="title" />
        <Link to="/featureTopics/1/edit">Edit</Link>
      </Datagrid>
    </List>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default FeatureTopicList
