import type { FC } from "react"
import type { MenuProps } from "react-admin"
import { Menu, MenuItemLink, useResourceDefinitions, useTranslate } from "react-admin"

import DefaultIcon from "@mui/icons-material/ViewList"
import { Box, Divider } from "@mui/material"

const SideMenu: FC<MenuProps> = (props) => {
  const resources = useResourceDefinitions()
  const t = useTranslate()

  return (
    <Menu {...props}>
      {Object.entries(resources).map(([name, resource]) => (
        <MenuItemLink
          key={name}
          to={`/${name}`}
          primaryText={t(`resources.${name}.name`)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        />
      ))}
      <Divider />
      <Box sx={{ m: 2 }}>
        <a href="/interviewTemplates">AI面接</a>
      </Box>
    </Menu>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default SideMenu
