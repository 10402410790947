import type React from "react"
import {
  BooleanField,
  EditBase,
  FormDataConsumer,
  ImageField,
  Labeled,
  RadioButtonGroupInput,
  RichTextField,
  SelectInput,
  Show,
  ShowBase,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin"

import { Stack } from "@mui/material"
import { JobCharacteristicLabelsInput, JobTargetGradesInput } from "common-components-ra"
import { domainMessages, objectToOptions } from "common-utils"

import { ButtonLink, ToolbarWithoutDelete } from "~/components/molecules"
import { jobSeekerAppHost } from "~/config"

import type { Job } from "rikeimatch-graphql"

const jobStatuses = ["draft", "submitted", "published", "unpublished"].map((status) => ({
  id: status,
  name: status,
}))

const JobTitle = () => {
  const { title } = useRecordContext() || {}

  return <span>{title}</span>
}

const PreviewLink = () => {
  const { id } = useRecordContext() || {}

  return <ButtonLink href={`${jobSeekerAppHost}/jobs/${id}`}>学生ページで見る</ButtonLink>
}

const ImagesField = () => {
  const { images, title } = useRecordContext<Job>()

  return (
    <Labeled label="画像">
      <Stack spacing={2} direction="row">
        {images?.map((img, i) => (
          <img
            key={img}
            alt={`${title}の画像${i + 1}`}
            src={img}
            style={{
              maxHeight: "240px",
              width: "100%",
              objectFit: "contain",
            }}
          />
        ))}
      </Stack>
    </Labeled>
  )
}

const JobEdit: React.FC = () => {
  const transform = (job: Job & { jobCharacteristicIds: string[] }) => {
    const { id, status, kind, targetGrades } = job

    // TODO: ちゃんと直す。characteristicLabels の値が混ざる
    const jobCharacteristicLabels = job.jobCharacteristicIds.map((jobCharacteristicId) => ({
      jobCharacteristicId,
    }))

    return { id, status, kind, targetGrades, jobCharacteristicLabels }
  }

  return (
    <Show title={<JobTitle />} resource="jobs">
      <SimpleShowLayout spacing={3}>
        <TextField source="id" />
        <ImageField
          source="coverImage"
          sx={{
            "& .RaImageField-image": {
              maxHeight: "300px",
              width: "100%",
              objectFit: "contain",
            },
          }}
        />
        <TextField source="title" />
      </SimpleShowLayout>

      <EditBase resource="jobs" transform={transform} spacing={3}>
        <SimpleForm
          toolbar={
            <ToolbarWithoutDelete>
              <PreviewLink />
            </ToolbarWithoutDelete>
          }
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                source="status"
                choices={jobStatuses.map((status) => ({
                  id: status.id,
                  name: status.name,
                  disabled: !formData.statusesCanChangeTo?.includes(status.name),
                }))}
                disableValue="disabled"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <JobTargetGradesInput />
          <JobCharacteristicLabelsInput />
          <RadioButtonGroupInput
            source="kind"
            choices={objectToOptions(domainMessages.resources.jobs.kinds)}
          />

          <ShowBase resource="jobs">
            <SimpleShowLayout sx={{ padding: 0 }} spacing={3}>
              <TextField source="company.name" label="resources.companies.name" />
              <BooleanField source="isFeatureable" label="特集ページへの掲載を許可する" />
              <RichTextField source="description" />
              <ImagesField />

              <RichTextField source="idealCandidate" />
            </SimpleShowLayout>
          </ShowBase>
        </SimpleForm>
      </EditBase>
    </Show>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobEdit
