import type { FC, MouseEventHandler, PropsWithChildren } from "react"
import { Button, SimpleForm, useTranslate } from "react-admin"
import type { SimpleFormProps } from "react-admin"

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

import type { DialogProps } from "@mui/material"

type DialogFormProps = {
  open: DialogProps["open"]
  loading: boolean
  onSubmit: SimpleFormProps["onSubmit"]
  onCancel: MouseEventHandler
  title?: string
  submitLabel?: string
  cancelLabel?: string
}

const DialogForm: FC<PropsWithChildren<DialogFormProps>> = ({
  open,
  loading,
  onSubmit,
  onCancel,
  title,
  submitLabel = "ra.action.save",
  cancelLabel = "ra.action.cancel",
  children,
}) => {
  const t = useTranslate()

  return (
    <Dialog open={open} fullWidth>
      <SimpleForm onSubmit={onSubmit} toolbar={<span />}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions sx={{ px: "24px" }}>
          {/* エラーになってしまうため、一時的に置き換える
          参考： https://cambiaworkspace.slack.com/archives/C024C9GC6NQ/p1668515297641609?thread_ts=1668514014.447539&cid=C024C9GC6NQ
          <SaveButton
            label={t(submitLabel)}
            saving={loading}
            icon={<span />}
            alwaysEnable
          /> */}
          <Button label={t(submitLabel)} disabled={loading} variant="contained" type="submit" />
          <Button
            onClick={onCancel}
            disabled={loading}
            sx={{ color: "primary.main" }}
            variant="text"
            label={t(cancelLabel)}
          />
        </DialogActions>
      </SimpleForm>
    </Dialog>
  )
}

DialogForm.defaultProps = {
  title: "",
  submitLabel: "ra.action.save",
  cancelLabel: "ra.action.cancel",
}

export { DialogForm }
export type { DialogFormProps }
