import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
/* eslint-disable no-unused-vars, no-shadow, prefer-arrow/prefer-arrow-functions */
import type {
  ChatGptMessageInputSource as AdminChatGptMessageInputSource,
  ChatGptMessageRole as AdminChatGptMessageRole,
  ChatGptThreadKind as AdminChatGptThreadKind,
  CreateCompanyInput as AdminCreateCompanyInput,
  CreateCompanyStaffInput as AdminCreateCompanyStaffInput,
  CreateFeaturedJobInput as AdminCreateFeaturedJobInput,
  CreateJobCharacteristicInput as AdminCreateJobCharacteristicInput,
  EducationHistoryGrade as AdminEducationHistoryGrade,
  FilterInput as AdminFilterInput,
  JobKind as AdminJobKind,
  JobStatus as AdminJobStatus,
  JobTargetGrade as AdminJobTargetGrade,
  MailNotificationInput as AdminMailNotificationInput,
  PaginationInput as AdminPaginationInput,
  SortInput as AdminSortInput,
  UpdateCompanyInput as AdminUpdateCompanyInput,
  UpdateCompanyPlanInput as AdminUpdateCompanyPlanInput,
  UpdateFeatureTopicInput as AdminUpdateFeatureTopicInput,
  UpdateJobCharacteristicInput as AdminUpdateJobCharacteristicInput,
  UpdateJobInput as AdminUpdateJobInput,
  Exact,
  InputMaybe,
  JobSeekerStatus,
  Scalars,
} from "rikeimatch-graphql"
export type AdminChatGptMessagesQueryVariables = Exact<{
  threadId: Scalars["ID"]
}>

export type AdminChatGptMessagesQuery = {
  chatGptMessages: Array<{
    id: string
    role: AdminChatGptMessageRole
    content: string
    inputSource: AdminChatGptMessageInputSource
    createdAt: string
  }>
}

export type AdminChatGptThreadsQueryVariables = Exact<{ [key: string]: never }>

export type AdminChatGptThreadsQuery = {
  chatGptThreads: Array<{
    id: string
    kind: AdminChatGptThreadKind
    createdAt: string
    jobSeeker?: {
      id: string
      createdAt: string
      fullName: string
      status: JobSeekerStatus
      address?: string | null
      studyKeywords: Array<string>
      studyDetail: string
      focusSummary?: string | null
      focusDetail?: string | null
      thingsChangedAfterCovid?: string | null
      educationHistory?: {
        id: string
        name: string
        grade: AdminEducationHistoryGrade
        faculty: string
        department: string
        major: string
        researchSubject?: string | null
        researchDetail?: string | null
        graduatedOn?: string | null
        highSchool?: string | null
      } | null
    } | null
  }>
}

export type AdminShowCompanyQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminShowCompanyQuery = {
  company?: {
    id: string
    name: string
    email: string
    foundedOn?: string | null
    employeesNumber?: number | null
    logo?: string | null
    coverImage?: string | null
    headquartersAddress?: string | null
    offices?: string | null
    homepage?: string | null
    industry?: string | null
    businessDescription?: string | null
    corporatePhilosophy?: string | null
    likeCount?: number | null
    scoutCount?: number | null
    expiresOn?: string | null
    locations: Array<string>
    businessCategories: Array<string>
    companyStaffs: Array<{ email: string; firstName: string; lastName: string }>
  } | null
}

export type AdminListCompaniesQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListCompaniesQuery = {
  companies: {
    data: Array<{
      id: string
      name: string
      email: string
      sentScoutCount?: number | null
      sentJobLikeCount?: number | null
      applicationCount?: number | null
      companyStaffs: Array<{ firstName: string; lastName: string }>
    }>
    pagination: { total: number }
  }
}

export type AdminShowCompaniesQueryVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"]
}>

export type AdminShowCompaniesQuery = {
  companiesById: Array<{
    id: string
    name: string
    email: string
    companyStaffs: Array<{ firstName: string; lastName: string }>
  }>
}

export type AdminCreateCompanyMutationVariables = Exact<{
  input: AdminCreateCompanyInput
}>

export type AdminCreateCompanyMutation = {
  createCompany: {
    id: string
    name: string
    email: string
    companyStaffs: Array<{ firstName: string; lastName: string }>
  }
}

export type AdminUpdateCompanyMutationVariables = Exact<{
  input: AdminUpdateCompanyInput
}>

export type AdminUpdateCompanyMutation = {
  adminUpdateCompany: {
    id: string
    name: string
    email: string
    businessCategories: Array<string>
    companyStaffs: Array<{ firstName: string; lastName: string }>
  }
}

export type AdminUpdateCompanyPlanMutationVariables = Exact<{
  input: AdminUpdateCompanyPlanInput
}>

export type AdminUpdateCompanyPlanMutation = {
  updateCompanyPlan: {
    id: string
    name: string
    email: string
    likeCount?: number | null
    scoutCount?: number | null
    expiresOn?: string | null
    companyStaffs: Array<{ firstName: string; lastName: string }>
  }
}

export type AdminCreateCompanyStaffMutationVariables = Exact<{
  input: AdminCreateCompanyStaffInput
}>

export type AdminCreateCompanyStaffMutation = {
  createCompanyStaff: { id: string; email: string; lastName: string; firstName: string }
}

export type AdminListFeatureTopicsQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListFeatureTopicsQuery = {
  featureTopics: {
    data: Array<{ id: string; slug: string; title: string }>
    pagination: { total: number }
  }
}

export type AdminShowFeatureTopicQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminShowFeatureTopicQuery = {
  featureTopic?: { id: string; slug: string; title: string; description: string } | null
}

export type AdminUpdateFeatureTopicMutationVariables = Exact<{
  input: AdminUpdateFeatureTopicInput
}>

export type AdminUpdateFeatureTopicMutation = {
  updateFeatureTopic: { id: string; slug: string; title: string; description: string }
}

export type AdminAllFeatureTopicsQueryVariables = Exact<{ [key: string]: never }>

export type AdminAllFeatureTopicsQuery = {
  allFeatureTopics: Array<{ id: string; slug: string; title: string; description: string }>
}

export type AdminListFeaturedJobsQueryVariables = Exact<{
  featureTopicId: Scalars["ID"]
}>

export type AdminListFeaturedJobsQuery = {
  featuredJobsByTopicId: Array<{
    id: string
    job: { id: string; title: string; company: { name: string } }
  }>
}

export type AdminDeleteFeaturedJobMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminDeleteFeaturedJobMutation = { deleteFeaturedJob: { success: boolean } }

export type AdminCreateFeaturedJobMutationVariables = Exact<{
  input: AdminCreateFeaturedJobInput
}>

export type AdminCreateFeaturedJobMutation = {
  createFeaturedJob: { id: string; job: { title: string; company: { name: string } } }
}

export type AdminListInterviewTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type AdminListInterviewTemplatesQuery = {
  interviewTemplates: Array<{
    id: string
    question: string
    answer: string
    evaluation: string
    updatedAt: string
  }>
}

export type AdminShowJobQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminShowJobQuery = {
  job?: {
    id: string
    coverImage?: string | null
    title: string
    description: string
    isFeatureable: boolean
    idealCandidate: string
    status: AdminJobStatus
    images?: Array<string> | null
    companyId: string
    statusesCanChangeTo: Array<AdminJobStatus>
    kind: AdminJobKind
    targetGrades: Array<AdminJobTargetGrade>
    company: { id: string; name: string }
    characteristicLabels: Array<{ id: string; jobCharacteristicId: string; name: string }>
  } | null
}

export type AdminListJobsQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListJobsQuery = {
  jobs: {
    data: Array<{
      id: string
      title: string
      status: AdminJobStatus
      companyId: string
      statusesCanChangeTo: Array<AdminJobStatus>
      isFeatureable: boolean
      company: { id: string; name: string }
    }>
    pagination: { total: number }
  }
}

export type AdminListJobsByCompanyQueryVariables = Exact<{
  companyId: Scalars["ID"]
  pagination?: InputMaybe<AdminPaginationInput>
}>

export type AdminListJobsByCompanyQuery = {
  jobsByCompany?: {
    data: Array<{
      id: string
      title: string
      status: AdminJobStatus
      companyId: string
      statusesCanChangeTo: Array<AdminJobStatus>
      company: { id: string }
    }>
    pagination: { total: number }
  } | null
}

export type AdminUpdateJobMutationVariables = Exact<{
  input: AdminUpdateJobInput
}>

export type AdminUpdateJobMutation = {
  updateJob?: { id: string; title: string; status: AdminJobStatus; companyId: string } | null
}

export type AdminUpdateJobStatusMutationVariables = Exact<{
  id: Scalars["ID"]
  status: AdminJobStatus
}>

export type AdminUpdateJobStatusMutation = {
  updateJobStatus?: {
    id: string
    title: string
    status: AdminJobStatus
    companyId: string
    statusesCanChangeTo: Array<AdminJobStatus>
  } | null
}

export type AdminListJobCharacteristicsQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListJobCharacteristicsQuery = {
  jobCharacteristics: {
    data: Array<{ id: string; name: string; jobCharacteristicLabels: Array<{ id: string }> }>
    pagination: { total: number }
  }
}

export type AdminCreateJobCharacteristicMutationVariables = Exact<{
  input: AdminCreateJobCharacteristicInput
}>

export type AdminCreateJobCharacteristicMutation = {
  createJobCharacteristic: { id: string; name: string }
}

export type AdminUpdateJobCharacteristicMutationVariables = Exact<{
  input: AdminUpdateJobCharacteristicInput
}>

export type AdminUpdateJobCharacteristicMutation = {
  updateJobCharacteristic: { id: string; name: string }
}

export type AdminDeleteJobCharacteristicMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminDeleteJobCharacteristicMutation = { deleteJobCharacteristic: { success: boolean } }

export type AdminJobCharacteristicsByIdQueryVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"]
}>

export type AdminJobCharacteristicsByIdQuery = {
  jobCharacteristicsById: Array<{ id: string; name: string }>
}

export type AdminCommonJobSeekerFragment = {
  id: string
  createdAt: string
  fullName: string
  status: JobSeekerStatus
  address?: string | null
  studyKeywords: Array<string>
  studyDetail: string
  focusSummary?: string | null
  focusDetail?: string | null
  thingsChangedAfterCovid?: string | null
  educationHistory?: {
    id: string
    name: string
    grade: AdminEducationHistoryGrade
    faculty: string
    department: string
    major: string
    researchSubject?: string | null
    researchDetail?: string | null
    graduatedOn?: string | null
    highSchool?: string | null
  } | null
}

export type AdminShowJobSeekerQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminShowJobSeekerQuery = {
  jobSeeker?: {
    email: string
    desiredJobTypes: Array<string>
    desiredWorkLocations: Array<string>
    desiredCompanySizes: Array<string>
    certifications: Array<string>
    id: string
    createdAt: string
    fullName: string
    status: JobSeekerStatus
    address?: string | null
    studyKeywords: Array<string>
    studyDetail: string
    focusSummary?: string | null
    focusDetail?: string | null
    thingsChangedAfterCovid?: string | null
    achievements: Array<{
      id: string
      title: string
      description: string
      url?: string | null
      attachment?: string | null
      date: string
    }>
    educationHistory?: {
      id: string
      name: string
      grade: AdminEducationHistoryGrade
      faculty: string
      department: string
      major: string
      researchSubject?: string | null
      researchDetail?: string | null
      graduatedOn?: string | null
      highSchool?: string | null
    } | null
  } | null
}

export type AdminListJobSeekersQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListJobSeekersQuery = {
  jobSeekers: {
    data: Array<{
      id: string
      createdAt: string
      fullName: string
      status: JobSeekerStatus
      address?: string | null
      studyKeywords: Array<string>
      studyDetail: string
      focusSummary?: string | null
      focusDetail?: string | null
      thingsChangedAfterCovid?: string | null
      educationHistory?: {
        id: string
        name: string
        grade: AdminEducationHistoryGrade
        faculty: string
        department: string
        major: string
        researchSubject?: string | null
        researchDetail?: string | null
        graduatedOn?: string | null
        highSchool?: string | null
      } | null
    }>
    pagination: { total: number }
  }
}

export type AdminDeleteJobSeekerMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminDeleteJobSeekerMutation = { deleteJobSeeker: { success: boolean } }

export type AdminListJobSeekerPrivacySettingsQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListJobSeekerPrivacySettingsQuery = {
  jobSeekerPrivacySettings: {
    data: Array<{
      id: string
      allowProfileAccessByCompanies: boolean
      allowProfilePublication: boolean
      allowEmailPublication: boolean
      jobSeeker: {
        id: string
        fullName: string
        slug: string
        bio?: string | null
        careerInterests: Array<string>
      }
    }>
    pagination: { total: number }
  }
}

export type AdminListMailNotificationsQueryVariables = Exact<{
  pagination?: InputMaybe<AdminPaginationInput>
  filters?: InputMaybe<Array<AdminFilterInput> | AdminFilterInput>
  sort?: InputMaybe<AdminSortInput>
}>

export type AdminListMailNotificationsQuery = {
  mailNotifications: {
    data: Array<{ id: string; subject: string; body: string; sendAt: string }>
    pagination: { total: number }
  }
}

export type AdminShowMailNotificationQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type AdminShowMailNotificationQuery = {
  mailNotification?: { id: string; subject: string; body: string; sendAt: string } | null
}

export type AdminCreateMailNotificationMutationVariables = Exact<{
  input: AdminMailNotificationInput
}>

export type AdminCreateMailNotificationMutation = {
  createMailNotification: { id: string; subject: string; body: string; sendAt: string }
}

export type AdminUpdateMailNotificationMutationVariables = Exact<{
  id: Scalars["ID"]
  input: AdminMailNotificationInput
}>

export type AdminUpdateMailNotificationMutation = {
  updateMailNotification: { id: string; subject: string; body: string; sendAt: string }
}

export const AdminCommonJobSeekerFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonJobSeeker" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "JobSeeker" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "fullName" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "studyKeywords" } },
          { kind: "Field", name: { kind: "Name", value: "studyDetail" } },
          { kind: "Field", name: { kind: "Name", value: "focusSummary" } },
          { kind: "Field", name: { kind: "Name", value: "focusDetail" } },
          { kind: "Field", name: { kind: "Name", value: "thingsChangedAfterCovid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "educationHistory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "grade" } },
                { kind: "Field", name: { kind: "Name", value: "faculty" } },
                { kind: "Field", name: { kind: "Name", value: "department" } },
                { kind: "Field", name: { kind: "Name", value: "major" } },
                { kind: "Field", name: { kind: "Name", value: "grade" } },
                { kind: "Field", name: { kind: "Name", value: "researchSubject" } },
                { kind: "Field", name: { kind: "Name", value: "researchDetail" } },
                { kind: "Field", name: { kind: "Name", value: "graduatedOn" } },
                { kind: "Field", name: { kind: "Name", value: "highSchool" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminCommonJobSeekerFragment, unknown>
export const AdminChatGptMessagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ChatGptMessages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "threadId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chatGptMessages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "threadId" },
                value: { kind: "Variable", name: { kind: "Name", value: "threadId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "role" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "inputSource" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminChatGptMessagesQuery, AdminChatGptMessagesQueryVariables>
export const AdminChatGptThreadsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ChatGptThreads" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chatGptThreads" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "kind" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "jobSeeker" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "fullName" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      { kind: "Field", name: { kind: "Name", value: "studyKeywords" } },
                      { kind: "Field", name: { kind: "Name", value: "studyDetail" } },
                      { kind: "Field", name: { kind: "Name", value: "focusSummary" } },
                      { kind: "Field", name: { kind: "Name", value: "focusDetail" } },
                      { kind: "Field", name: { kind: "Name", value: "thingsChangedAfterCovid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "educationHistory" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "grade" } },
                            { kind: "Field", name: { kind: "Name", value: "faculty" } },
                            { kind: "Field", name: { kind: "Name", value: "department" } },
                            { kind: "Field", name: { kind: "Name", value: "major" } },
                            { kind: "Field", name: { kind: "Name", value: "grade" } },
                            { kind: "Field", name: { kind: "Name", value: "researchSubject" } },
                            { kind: "Field", name: { kind: "Name", value: "researchDetail" } },
                            { kind: "Field", name: { kind: "Name", value: "graduatedOn" } },
                            { kind: "Field", name: { kind: "Name", value: "highSchool" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminChatGptThreadsQuery, AdminChatGptThreadsQueryVariables>
export const AdminShowCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShowCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "foundedOn" } },
                { kind: "Field", name: { kind: "Name", value: "employeesNumber" } },
                { kind: "Field", name: { kind: "Name", value: "logo" } },
                { kind: "Field", name: { kind: "Name", value: "coverImage" } },
                { kind: "Field", name: { kind: "Name", value: "headquartersAddress" } },
                { kind: "Field", name: { kind: "Name", value: "offices" } },
                { kind: "Field", name: { kind: "Name", value: "homepage" } },
                { kind: "Field", name: { kind: "Name", value: "industry" } },
                { kind: "Field", name: { kind: "Name", value: "businessDescription" } },
                { kind: "Field", name: { kind: "Name", value: "corporatePhilosophy" } },
                { kind: "Field", name: { kind: "Name", value: "likeCount" } },
                { kind: "Field", name: { kind: "Name", value: "scoutCount" } },
                { kind: "Field", name: { kind: "Name", value: "expiresOn" } },
                { kind: "Field", name: { kind: "Name", value: "locations" } },
                { kind: "Field", name: { kind: "Name", value: "businessCategories" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyStaffs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminShowCompanyQuery, AdminShowCompanyQueryVariables>
export const AdminListCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "sentScoutCount" } },
                      { kind: "Field", name: { kind: "Name", value: "sentJobLikeCount" } },
                      { kind: "Field", name: { kind: "Name", value: "applicationCount" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyStaffs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListCompaniesQuery, AdminListCompaniesQueryVariables>
export const AdminShowCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShowCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companiesById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: { kind: "Variable", name: { kind: "Name", value: "ids" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyStaffs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminShowCompaniesQuery, AdminShowCompaniesQueryVariables>
export const AdminCreateCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateCompanyInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyStaffs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminCreateCompanyMutation, AdminCreateCompanyMutationVariables>
export const AdminUpdateCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateCompanyInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminUpdateCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "businessCategories" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyStaffs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUpdateCompanyMutation, AdminUpdateCompanyMutationVariables>
export const AdminUpdateCompanyPlanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCompanyPlan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateCompanyPlanInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCompanyPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "likeCount" } },
                { kind: "Field", name: { kind: "Name", value: "scoutCount" } },
                { kind: "Field", name: { kind: "Name", value: "expiresOn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyStaffs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateCompanyPlanMutation,
  AdminUpdateCompanyPlanMutationVariables
>
export const AdminCreateCompanyStaffDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCompanyStaff" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateCompanyStaffInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCompanyStaff" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminCreateCompanyStaffMutation,
  AdminCreateCompanyStaffMutationVariables
>
export const AdminListFeatureTopicsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListFeatureTopics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "featureTopics" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListFeatureTopicsQuery, AdminListFeatureTopicsQueryVariables>
export const AdminShowFeatureTopicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShowFeatureTopic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "featureTopic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminShowFeatureTopicQuery, AdminShowFeatureTopicQueryVariables>
export const AdminUpdateFeatureTopicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateFeatureTopic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateFeatureTopicInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFeatureTopic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateFeatureTopicMutation,
  AdminUpdateFeatureTopicMutationVariables
>
export const AdminAllFeatureTopicsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllFeatureTopics" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allFeatureTopics" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAllFeatureTopicsQuery, AdminAllFeatureTopicsQueryVariables>
export const AdminListFeaturedJobsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListFeaturedJobs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "featureTopicId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "featuredJobsByTopicId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "featureTopicId" },
                value: { kind: "Variable", name: { kind: "Name", value: "featureTopicId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListFeaturedJobsQuery, AdminListFeaturedJobsQueryVariables>
export const AdminDeleteFeaturedJobDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteFeaturedJob" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteFeaturedJob" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminDeleteFeaturedJobMutation,
  AdminDeleteFeaturedJobMutationVariables
>
export const AdminCreateFeaturedJobDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateFeaturedJob" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateFeaturedJobInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createFeaturedJob" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminCreateFeaturedJobMutation,
  AdminCreateFeaturedJobMutationVariables
>
export const AdminListInterviewTemplatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListInterviewTemplates" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "interviewTemplates" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "question" } },
                { kind: "Field", name: { kind: "Name", value: "answer" } },
                { kind: "Field", name: { kind: "Name", value: "evaluation" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminListInterviewTemplatesQuery,
  AdminListInterviewTemplatesQueryVariables
>
export const AdminShowJobDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShowJob" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "job" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "coverImage" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "isFeatureable" } },
                { kind: "Field", name: { kind: "Name", value: "idealCandidate" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "images" } },
                { kind: "Field", name: { kind: "Name", value: "companyId" } },
                { kind: "Field", name: { kind: "Name", value: "statusesCanChangeTo" } },
                { kind: "Field", name: { kind: "Name", value: "kind" } },
                { kind: "Field", name: { kind: "Name", value: "targetGrades" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "characteristicLabels" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "jobCharacteristicId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminShowJobQuery, AdminShowJobQueryVariables>
export const AdminListJobsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListJobs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobs" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "companyId" } },
                      { kind: "Field", name: { kind: "Name", value: "statusesCanChangeTo" } },
                      { kind: "Field", name: { kind: "Name", value: "isFeatureable" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListJobsQuery, AdminListJobsQueryVariables>
export const AdminListJobsByCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "listJobsByCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobsByCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyId" },
                value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "companyId" } },
                      { kind: "Field", name: { kind: "Name", value: "statusesCanChangeTo" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListJobsByCompanyQuery, AdminListJobsByCompanyQueryVariables>
export const AdminUpdateJobDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateJob" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateJobInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateJob" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "companyId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUpdateJobMutation, AdminUpdateJobMutationVariables>
export const AdminUpdateJobStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateJobStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "status" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "JobStatus" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateJobStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: { kind: "Variable", name: { kind: "Name", value: "status" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "companyId" } },
                { kind: "Field", name: { kind: "Name", value: "statusesCanChangeTo" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminUpdateJobStatusMutation, AdminUpdateJobStatusMutationVariables>
export const AdminListJobCharacteristicsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListJobCharacteristics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobCharacteristics" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobCharacteristicLabels" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminListJobCharacteristicsQuery,
  AdminListJobCharacteristicsQueryVariables
>
export const AdminCreateJobCharacteristicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateJobCharacteristic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateJobCharacteristicInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createJobCharacteristic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminCreateJobCharacteristicMutation,
  AdminCreateJobCharacteristicMutationVariables
>
export const AdminUpdateJobCharacteristicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateJobCharacteristic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateJobCharacteristicInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateJobCharacteristic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateJobCharacteristicMutation,
  AdminUpdateJobCharacteristicMutationVariables
>
export const AdminDeleteJobCharacteristicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteJobCharacteristic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteJobCharacteristic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminDeleteJobCharacteristicMutation,
  AdminDeleteJobCharacteristicMutationVariables
>
export const AdminJobCharacteristicsByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "JobCharacteristicsById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobCharacteristicsById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: { kind: "Variable", name: { kind: "Name", value: "ids" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminJobCharacteristicsByIdQuery,
  AdminJobCharacteristicsByIdQueryVariables
>
export const AdminShowJobSeekerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShowJobSeeker" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobSeeker" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CommonJobSeeker" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "desiredJobTypes" } },
                { kind: "Field", name: { kind: "Name", value: "desiredWorkLocations" } },
                { kind: "Field", name: { kind: "Name", value: "desiredCompanySizes" } },
                { kind: "Field", name: { kind: "Name", value: "certifications" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "achievements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      { kind: "Field", name: { kind: "Name", value: "attachment" } },
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "educationHistory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "grade" } },
                      { kind: "Field", name: { kind: "Name", value: "faculty" } },
                      { kind: "Field", name: { kind: "Name", value: "department" } },
                      { kind: "Field", name: { kind: "Name", value: "major" } },
                      { kind: "Field", name: { kind: "Name", value: "grade" } },
                      { kind: "Field", name: { kind: "Name", value: "researchSubject" } },
                      { kind: "Field", name: { kind: "Name", value: "researchDetail" } },
                      { kind: "Field", name: { kind: "Name", value: "graduatedOn" } },
                      { kind: "Field", name: { kind: "Name", value: "highSchool" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonJobSeeker" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "JobSeeker" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "fullName" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "studyKeywords" } },
          { kind: "Field", name: { kind: "Name", value: "studyDetail" } },
          { kind: "Field", name: { kind: "Name", value: "focusSummary" } },
          { kind: "Field", name: { kind: "Name", value: "focusDetail" } },
          { kind: "Field", name: { kind: "Name", value: "thingsChangedAfterCovid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "educationHistory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "grade" } },
                { kind: "Field", name: { kind: "Name", value: "faculty" } },
                { kind: "Field", name: { kind: "Name", value: "department" } },
                { kind: "Field", name: { kind: "Name", value: "major" } },
                { kind: "Field", name: { kind: "Name", value: "grade" } },
                { kind: "Field", name: { kind: "Name", value: "researchSubject" } },
                { kind: "Field", name: { kind: "Name", value: "researchDetail" } },
                { kind: "Field", name: { kind: "Name", value: "graduatedOn" } },
                { kind: "Field", name: { kind: "Name", value: "highSchool" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminShowJobSeekerQuery, AdminShowJobSeekerQueryVariables>
export const AdminListJobSeekersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListJobSeekers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobSeekers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "FragmentSpread", name: { kind: "Name", value: "CommonJobSeeker" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonJobSeeker" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "JobSeeker" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "fullName" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "studyKeywords" } },
          { kind: "Field", name: { kind: "Name", value: "studyDetail" } },
          { kind: "Field", name: { kind: "Name", value: "focusSummary" } },
          { kind: "Field", name: { kind: "Name", value: "focusDetail" } },
          { kind: "Field", name: { kind: "Name", value: "thingsChangedAfterCovid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "educationHistory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "grade" } },
                { kind: "Field", name: { kind: "Name", value: "faculty" } },
                { kind: "Field", name: { kind: "Name", value: "department" } },
                { kind: "Field", name: { kind: "Name", value: "major" } },
                { kind: "Field", name: { kind: "Name", value: "grade" } },
                { kind: "Field", name: { kind: "Name", value: "researchSubject" } },
                { kind: "Field", name: { kind: "Name", value: "researchDetail" } },
                { kind: "Field", name: { kind: "Name", value: "graduatedOn" } },
                { kind: "Field", name: { kind: "Name", value: "highSchool" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminListJobSeekersQuery, AdminListJobSeekersQueryVariables>
export const AdminDeleteJobSeekerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteJobSeeker" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteJobSeeker" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminDeleteJobSeekerMutation, AdminDeleteJobSeekerMutationVariables>
export const AdminListJobSeekerPrivacySettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListJobSeekerPrivacySettings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "jobSeekerPrivacySettings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowProfileAccessByCompanies" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "allowProfilePublication" } },
                      { kind: "Field", name: { kind: "Name", value: "allowEmailPublication" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobSeeker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fullName" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "bio" } },
                            { kind: "Field", name: { kind: "Name", value: "careerInterests" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminListJobSeekerPrivacySettingsQuery,
  AdminListJobSeekerPrivacySettingsQueryVariables
>
export const AdminListMailNotificationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListMailNotifications" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "FilterInput" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mailNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "subject" } },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "sendAt" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "total" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminListMailNotificationsQuery,
  AdminListMailNotificationsQueryVariables
>
export const AdminShowMailNotificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShowMailNotification" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mailNotification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "sendAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminShowMailNotificationQuery,
  AdminShowMailNotificationQueryVariables
>
export const AdminCreateMailNotificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateMailNotification" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "MailNotificationInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMailNotification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "sendAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminCreateMailNotificationMutation,
  AdminCreateMailNotificationMutationVariables
>
export const AdminUpdateMailNotificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateMailNotification" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "MailNotificationInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMailNotification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "sendAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminUpdateMailNotificationMutation,
  AdminUpdateMailNotificationMutationVariables
>
