import { gql, useQuery } from "@apollo/client"

import type { AdminChatGptMessagesQuery } from "~/generated/graphql"

const LIST_CHAT_GPT_MESSAGES = gql`
  query ChatGptMessages($threadId: ID!) {
    chatGptMessages(threadId: $threadId) {
      id
      role
      content
      inputSource
      createdAt
    }
  }
`

type PropsType = {
  threadId?: string
}

const useChatGptMessages = ({ threadId }: PropsType) => {
  const { data, error, loading } = useQuery<AdminChatGptMessagesQuery>(LIST_CHAT_GPT_MESSAGES, {
    variables: { threadId: threadId || "" },
    skip: !threadId,
  })
  const chatGptMessages = data?.chatGptMessages

  return {
    chatGptMessages,
    error,
    loading,
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useChatGptMessages
