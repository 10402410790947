import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { Auth } from "aws-amplify"

import { apiHost } from "./config"

import type { DefaultOptions } from "@apollo/client"

type RequestHeaders = {
  headers: {
    authorization: string
    [key: string]: string
  }
}

const authLink = setContext(async (_, { headers }): Promise<RequestHeaders> => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()

  return {
    headers: {
      ...(headers as Record<string, string>),
      authorization: `Bearer ${token}`,
    },
  }
})

const httpLink = createHttpLink({
  uri: `${apiHost}/graphql`,
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  name: "admin",
  version: process.env.VITE_REVISION,
  defaultOptions,
})

// biome-ignore lint/style/noDefaultExport: ...
export default client
