import { Datagrid, List, SearchInput, TextField } from "react-admin"

const companyFilter = [<SearchInput key="companyFilter" source="q" alwaysOn />]

const CompanyList = () => (
  <List resource="companies" filters={companyFilter} sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="sentScoutCount" />
      <TextField source="sentJobLikeCount" />
      <TextField source="applicationCount" />
    </Datagrid>
  </List>
)

// biome-ignore lint/style/noDefaultExport: ...
export default CompanyList
