import { Skeleton as ChakraSkeleton, Container, Stack } from "@chakra-ui/react"

const Skeleton = () => (
  <Container>
    <Stack>
      <ChakraSkeleton height="20px" />
      <ChakraSkeleton height="20px" />
      <ChakraSkeleton height="20px" />
    </Stack>
  </Container>
)

// biome-ignore lint/style/noDefaultExport: ...
export default Skeleton
