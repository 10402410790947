import type React from "react"

import { Box, Typography } from "@mui/material"

const PreviewTextField: React.FC<{
  text: string | undefined | null
  maxChars: number
  showCount?: boolean
}> = ({ text, maxChars, showCount = false }) => (
  <Box sx={{ inlineSize: "10rem" }}>
    {text ? (
      <Typography component="span" variant="body2">
        {text.length > maxChars ? `${text.slice(0, 50)}...` : text}
      </Typography>
    ) : (
      "未記入"
    )}
    {showCount && text && (
      <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
        {`（${text.length}字）`}
      </Typography>
    )}
  </Box>
)

PreviewTextField.defaultProps = {
  showCount: false,
}

// biome-ignore lint/style/noDefaultExport: ...
export default PreviewTextField
