import type React from "react"
import { DateTimeInput, Edit, SimpleForm, TextInput, required, useRecordContext } from "react-admin"

import { ErbVarsHelpText, ToolbarWithoutDelete } from "~/components/molecules"
import { erbifyVars, prettifyErbVars } from "~/utilities/transformErb"

import type { MailNotification } from "rikeimatch-graphql"

const MailNotificationTitle = () => {
  const { subject } = useRecordContext() || {}

  return <span>{subject}</span>
}

const transform = (mail: MailNotification) => {
  const { body, ...params } = mail
  const erbifiedBody = erbifyVars(body)

  return { ...params, body: erbifiedBody }
}

const MailNotificationEdit: React.FC = () => (
  <Edit title={<MailNotificationTitle />} resource="mailNotifications" transform={transform}>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <ErbVarsHelpText />
      <TextInput source="subject" fullWidth validate={[required()]} />
      <TextInput
        source="body"
        fullWidth
        multiline
        format={(body: string) => prettifyErbVars(body)}
        validate={[required()]}
      />
      <DateTimeInput source="sendAt" validate={[required()]} />
    </SimpleForm>
  </Edit>
)

// biome-ignore lint/style/noDefaultExport: ...
export default MailNotificationEdit
