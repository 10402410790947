import type React from "react"
import {
  BooleanField,
  Button,
  Datagrid,
  DeleteButton,
  Edit,
  FunctionField,
  List,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useCreate,
  useCreatePath,
  useEditController,
  useGetList,
  useNotify,
  useRecordContext,
  useTranslate,
} from "react-admin"

import { Link } from "@mui/material"

import { jobSeekerAppHost } from "~/config"

import type { FeatureTopic, FeaturedJob, Job } from "rikeimatch-graphql"

const FeatureTopicTitle = () => {
  const { slug } = useRecordContext<FeatureTopic>() || {}

  return <span>{slug}</span>
}

const FeatureTopicEdit: React.FC = () => {
  const { record: topic } = useEditController<FeatureTopic>()
  const createPath = useCreatePath()

  const [create] = useCreate()
  const notify = useNotify()
  const t = useTranslate()

  const { data: featuredJobs, refetch } = useGetList<FeaturedJob>("featuredJobs", {
    filter: { featureTopicId: topic?.id || "" },
  })
  const createFeaturedJob = (job: Job): void => {
    if (!topic) {
      return
    }
    void create(
      "featuredJobs",
      {
        data: {
          jobId: job.id,
          featureTopicId: topic.id,
        },
      },
      {
        onSuccess: () => {
          notify(t("ra.notification.created"), { type: "success" })
          void refetch()
        },
        onError: () => {
          notify("notification.http_error", { type: "error" })
        },
      },
    )
  }

  const url = `${jobSeekerAppHost}/topics/${topic?.slug || ""}`

  return (
    <div>
      <Edit redirect={false} title={<FeatureTopicTitle />}>
        <SimpleForm>
          <TextInput disabled source="id" />
          <TextInput source="slug" validate={[required()]} />
          <TextInput source="title" validate={[required()]} />
          <TextInput multiline fullWidth source="description" validate={[required()]} />
          Preview
          <Link href={url} target="_blank">
            {url}
          </Link>
        </SimpleForm>
      </Edit>

      {(featuredJobs?.length || 0) > 0 && (
        <List
          resource="featuredJobs"
          filter={{ featureTopicId: topic?.id || "" }}
          title=" "
          disableSyncWithLocation
        >
          <Datagrid
            rowClick={(_, __, record) =>
              createPath({
                resource: "jobs",
                id: (record as FeaturedJob).job.id,
                type: "edit",
              })
            }
          >
            <TextField source="job.title" label="resources.jobs.fields.title" />
            <TextField source="job.company.name" label="resources.companies.fields.name" />
            <DeleteButton redirect={false} resource="featuredJobs" />
          </Datagrid>
        </List>
      )}

      <List
        resource="jobs"
        // TODO: filter={{ field: 'isFeatureable', value: true }} があるとよさそう
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={25}
        title=" "
      >
        <Datagrid>
          <TextField source="title" />
          <TextField source="company.name" label="resources.companies.fields.name" />
          <BooleanField source="isFeatureable" />
          <FunctionField
            label="ra.action.add"
            render={(job: Job) => {
              const disabled =
                !job.isFeatureable || featuredJobs?.map((f) => f.job.id)?.includes(job.id)

              return (
                <Button
                  label="ra.action.add"
                  onClick={() => createFeaturedJob(job)}
                  disabled={disabled}
                />
              )
            }}
          />
          {/* TODO: Datagrid rowClick="edit"に置き換える */}
          <ShowButton />
        </Datagrid>
      </List>
    </div>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default FeatureTopicEdit
