import type { ReactNode } from "react"

import { ApolloProvider as ApolloHooksProvider } from "@apollo/client"

import client from "~/apolloClient"

const ApolloProvider = ({ children }: { children: ReactNode }) => (
  <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
)

// biome-ignore lint/style/noDefaultExport: ...
export default ApolloProvider
