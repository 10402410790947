import type React from "react"
import { useRecordContext } from "react-admin"

import Typography from "@mui/material/Typography"
import { type JobSeeker, JobSeekerStatus } from "rikeimatch-graphql"

const JobSeekerStatusField: React.FC<{
  source: keyof JobSeeker
  addLabel?: boolean
}> = ({ source }) => {
  const originalRecord = useRecordContext<JobSeeker>()
  if (!originalRecord) return null

  const replacedStatus = () => {
    const statusName = originalRecord[source]
    switch (statusName) {
      case JobSeekerStatus.CollectingInformation:
        return "未内定"
      case JobSeekerStatus.LookingForInternship:
        return "未内定"
      case JobSeekerStatus.LookingForJob:
        return "未内定"
      case JobSeekerStatus.HasOfferAndLookingForJob:
        return "内定あり（就活継続中）"
      case JobSeekerStatus.AcceptedOffer:
        return "内定承諾（就活終了）"
      default:
        return ""
    }
  }

  return (
    <Typography component="span" variant="body2">
      {replacedStatus()}
    </Typography>
  )
}

JobSeekerStatusField.defaultProps = {
  addLabel: true,
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobSeekerStatusField
