import { Box, Chip, Stack, Typography } from "@mui/material"

import type { AdminChatGptMessagesQuery } from "~/generated/graphql"

type PropsType = {
  messages: AdminChatGptMessagesQuery["chatGptMessages"]
}

const ChatGptThreadListMessages = ({ messages }: PropsType) => (
  <Stack spacing={2} sx={{ p: 4 }}>
    {messages.map((message) => (
      <Box key={message.id}>
        <Typography variant="caption">{message?.createdAt}</Typography>
        <Chip label={message?.role} />
        <Typography sx={{ whiteSpace: "pre-wrap" }}>{message?.content}</Typography>
      </Box>
    ))}
  </Stack>
)

// biome-ignore lint/style/noDefaultExport: ...
export default ChatGptThreadListMessages
