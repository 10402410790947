import type { AwsConfig } from "common-utils"

const authConfig: AwsConfig["Auth"] = {
  region: "ap-northeast-1",
  userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID,
}

// biome-ignore lint/style/noDefaultExport: ...
export default authConfig
