import { ReferenceArrayInput, SelectArrayInput, useGetList, useRecordContext } from "react-admin"

import { Skeleton } from "common-components-chakra"

import type { Job, JobCharacteristic, JobCharacteristicLabel } from "rikeimatch-graphql"

const JobCharacteristicLabelsInput = () => {
  const { data: jobCharacteristics, isLoading } =
    useGetList<JobCharacteristic>("jobCharacteristics")
  const choices = jobCharacteristics?.map((jc) => ({
    id: jc.id,
    name: jc.name,
  }))
  const job: Job = useRecordContext()

  if (isLoading || !choices) {
    return <Skeleton />
  }

  return (
    <ReferenceArrayInput source="jobCharacteristicIds" reference="jobCharacteristics">
      <SelectArrayInput
        alwaysOn
        label="resources.jobs.fields.characteristicLabels"
        sx={{ minWidth: "140px" }}
        defaultValue={
          job?.characteristicLabels
            ? job.characteristicLabels.map(
                (characteristicLabel: JobCharacteristicLabel) =>
                  characteristicLabel.jobCharacteristicId,
              )
            : []
        }
        validate={(value: string[] | undefined) => {
          if (value?.length === 0) {
            return "求人の特徴ラベルを1つ以上選択してください"
          }

          return null
        }}
      />
    </ReferenceArrayInput>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobCharacteristicLabelsInput
