import type React from "react"
import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  List,
  SearchInput,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin"

import { Box, Chip, Stack, Typography } from "@mui/material"
import {
  EducationGradeField,
  JOB_SEEKER_DEFAULT_SORT_BY,
  JobSeekerPrefectureField,
  JobSeekerSortControl,
  JobSeekerStatusField,
} from "common-components-ra"
import { domainMessages, i18n, jobSeekerStatuses, objectToOptions } from "common-utils"

import { PreviewTextField } from "~/components/molecules"

import type { JobSeeker } from "rikeimatch-graphql"

const prefectures = objectToOptions(domainMessages.resources.prefecturesForProfile)
const grades = objectToOptions(i18n.grades)

const jobSeekerFilter = [
  <SearchInput key="freeSearch" source="q" alwaysOn />,
  <SelectArrayInput key="jobSeekerStatuses" source="status" choices={jobSeekerStatuses} />,
  <TextInput
    key="educationHistoryName"
    label="resources.educationHistory.fields.name"
    source="educationHistoryName"
  />,
  <SelectArrayInput
    key="educationHistoryGrade"
    label="resources.educationHistory.fields.grade"
    source="educationHistoryGrade"
    choices={grades}
  />,
  <TextInput
    key="educationHistoryFaculty"
    label="resources.educationHistory.fields.faculty"
    source="educationHistoryFaculty"
  />,
  <TextInput
    key="educationHistoryDepartment"
    label="resources.educationHistory.fields.department"
    source="educationHistoryDepartment"
  />,
  <TextInput
    key="educationHistoryMajor"
    label="resources.educationHistory.fields.major"
    source="educationHistoryMajor"
  />,
  <AutocompleteArrayInput key="prefectures" source="address" choices={prefectures} />,
]

const Actions = () => (
  <TopToolbar>
    <FilterButton />

    <JobSeekerSortControl />
  </TopToolbar>
)

const JobSeekerList: React.FC = () => (
  <List
    resource="jobSeekers"
    actions={<Actions />}
    filters={jobSeekerFilter}
    // @ts-expect-error
    sort={JOB_SEEKER_DEFAULT_SORT_BY}
  >
    <Datagrid rowClick="show">
      <TextField source="fullName" sx={{ wordBreak: "keep-all" }} />
      <DateField
        source="createdAt"
        locales="ja-JP"
        options={{
          dateStyle: "medium",
        }}
      />
      <JobSeekerStatusField source="status" />
      <JobSeekerPrefectureField source="address" />
      <TextField source="educationHistory.name" label="resources.educationHistory.fields.name" />
      <EducationGradeField
        source="educationHistory.grade"
        label="resources.educationHistory.fields.grade"
      />
      <TextField
        source="educationHistory.faculty"
        label="resources.educationHistory.fields.faculty"
      />
      <TextField
        source="educationHistory.department"
        label="resources.educationHistory.fields.department"
      />
      <TextField source="educationHistory.major" label="resources.educationHistory.fields.major" />
      <FunctionField
        label="resources.jobSeekers.fields.studyKeywords"
        render={(jobSeeker: JobSeeker) =>
          jobSeeker?.studyKeywords.length > 0 ? (
            <Stack direction="column" spacing="3px" sx={{ inlineSize: "100px" }}>
              {jobSeeker.studyKeywords.map((studyKeyword) => (
                <Chip key={studyKeyword} label={studyKeyword} sx={{ marginRight: "5px" }} />
              ))}
            </Stack>
          ) : (
            <Typography component="span" variant="body2">
              未入力
            </Typography>
          )
        }
      />
      <FunctionField
        label="resources.jobSeekers.fields.studyDetail"
        render={(jobSeeker: JobSeeker) => (
          <PreviewTextField text={jobSeeker.studyDetail} maxChars={50} showCount />
        )}
      />
      <FunctionField
        label="resources.jobSeekers.fields.focusSummary"
        render={(jobSeeker: JobSeeker) => (
          <PreviewTextField text={jobSeeker.focusSummary} maxChars={50} showCount />
        )}
      />
      <FunctionField
        label="resources.jobSeekers.fields.focusDetail"
        render={(jobSeeker: JobSeeker) => (
          <Box sx={{ inlineSize: "100px" }}>
            <PreviewTextField text={jobSeeker.focusDetail} maxChars={50} showCount />
          </Box>
        )}
      />
    </Datagrid>
  </List>
)

// biome-ignore lint/style/noDefaultExport: ...
export default JobSeekerList
