import { ReferenceArrayInput, SelectArrayInput, useTranslate } from "react-admin"

import { JobTargetGrade } from "rikeimatch-graphql"

const JobTargetGradesInput = () => {
  const t = useTranslate()

  const orderedChoices = [
    JobTargetGrade.Graduation_2024,
    JobTargetGrade.Graduation_2025,
    JobTargetGrade.Graduation_2026,
    JobTargetGrade.Graduation_2027,
    JobTargetGrade.Graduation_2028,
    JobTargetGrade.All,
  ]

  const choices = orderedChoices.map((value) => {
    return {
      id: value,
      name: t(`resources.jobs.targetGrades.${value}`),
    }
  })

  return (
    <ReferenceArrayInput source="targetGrades" reference="targetGrades">
      <SelectArrayInput
        alwaysOn
        source="targetGrades"
        label="resources.jobs.fields.targetGrades"
        sx={{ minWidth: "140px" }}
        choices={choices}
        validate={(value: string[] | undefined) => {
          if (value?.length === 0) {
            return "対象の卒業年度を1つ以上選択してください"
          }

          return null
        }}
      />
    </ReferenceArrayInput>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobTargetGradesInput
