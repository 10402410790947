import { gql, useQuery } from "@apollo/client"

import type { AdminChatGptThreadsQuery } from "~/generated/graphql"

const LIST_CHAT_GPT_THREADS = gql`
  query ChatGptThreads {
    chatGptThreads {
      id
      kind
      createdAt
      jobSeeker {
        id
        createdAt
        fullName
        status
        address
        studyKeywords
        studyDetail
        focusSummary
        focusDetail
        thingsChangedAfterCovid
        educationHistory {
          id
          name
          grade
          faculty
          department
          major
          grade
          researchSubject
          researchDetail
          graduatedOn
          highSchool
        }
      }
    }
  }
`

const useChatGptThreads = () => {
  const { data, error, loading } = useQuery<AdminChatGptThreadsQuery>(LIST_CHAT_GPT_THREADS)
  const chatGptThreads = data?.chatGptThreads

  return {
    chatGptThreads,
    error,
    loading,
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useChatGptThreads
