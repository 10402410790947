import type React from "react"
import { Create, DateTimeInput, SimpleForm, TextInput, required } from "react-admin"

import { ErbVarsHelpText } from "~/components/molecules"
import { erbifyVars, prettifyErbVars } from "~/utilities/transformErb"

import type { MailNotification } from "rikeimatch-graphql"

const transform = (mail: MailNotification) => {
  const { body, ...params } = mail
  const erbifiedBody = erbifyVars(body)

  return { ...params, body: erbifiedBody }
}

const MailNotificationCreate: React.FC = () => (
  <Create resource="mailNotifications" transform={transform}>
    <SimpleForm>
      <ErbVarsHelpText />
      <TextInput source="subject" fullWidth validate={[required()]} />
      <TextInput
        source="body"
        fullWidth
        multiline
        format={(body: string) => prettifyErbVars(body)}
        validate={[required()]}
      />
      <DateTimeInput source="sendAt" validate={[required()]} />
    </SimpleForm>
  </Create>
)

// biome-ignore lint/style/noDefaultExport: ...
export default MailNotificationCreate
