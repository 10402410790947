import type React from "react"
import { useState } from "react"
import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  List,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useCreate,
  useNotify,
  useRefresh,
  useUpdate,
} from "react-admin"
import type { FieldValues } from "react-hook-form"

import { DialogForm } from "common-components-ra"

import type { JobCharacteristic } from "rikeimatch-graphql"

const ListActions = () => {
  const [open, setOpen] = useState(false)
  const [create] = useCreate()
  const notify = useNotify()

  const handleOnSubmit = async (values: FieldValues): Promise<void> => {
    await create(
      "jobCharacteristics",
      {
        data: values,
      },
      {
        onSuccess: () => {
          notify("ra.notification.created", { type: "success" })
          setOpen(false)
        },
      },
    )
  }

  return (
    <TopToolbar>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        label="ra.action.create"
      />
      <DialogForm
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={handleOnSubmit}
        loading={false}
        title="求人ラベルを作成する"
      >
        <TextInput
          source="name"
          label="resources.jobCharacteristics.fields.name"
          validate={required()}
          fullWidth
        />
      </DialogForm>
    </TopToolbar>
  )
}

const JobCharacteristicsList: React.FC = () => {
  const [jobCharacteristic, setJobCharacteristic] = useState<JobCharacteristic | undefined>()
  const refresh = useRefresh()
  const [update] = useUpdate()
  const notify = useNotify()

  const handleOnSubmit = async (values: FieldValues): Promise<void> => {
    await update(
      "jobCharacteristics",
      {
        data: {
          id: jobCharacteristic?.id,
          ...values,
        },
      },
      {
        onSuccess: () => {
          // FIXME: 更新後のメッセージが  |||| %{smart_count}件更新しました と表示される
          notify("ra.notification.updated", { type: "success" })
          setJobCharacteristic(undefined)
          refresh()
        },
      },
    )
  }

  return (
    <>
      <List
        resource="jobCharacteristics"
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        empty={false}
      >
        <Datagrid
          rowClick={(_1, _2, record) => {
            setJobCharacteristic(record as JobCharacteristic)

            // TODO: 何を返せばいいのだろうか
            return record.id as string
          }}
        >
          <TextField source="name" />
          <FunctionField
            label="resources.jobCharacteristics.fields.jobCharacteristicLabelsCount"
            render={(record: JobCharacteristic) => (
              <span>{record.jobCharacteristicLabels.length}</span>
            )}
          />
          <FunctionField
            label="ra.action.edit"
            render={(record: JobCharacteristic) => (
              <Button onClick={() => setJobCharacteristic(record)} label="ra.action.edit" />
            )}
          />
          <FunctionField
            render={(record: JobCharacteristic) => (
              <DeleteWithConfirmButton
                confirmContent={
                  <div>
                    <p>
                      {record.jobCharacteristicLabels.length}
                      件の求人に使用されています。
                    </p>
                    <p>本当に削除してよろしいですか？</p>
                  </div>
                }
              />
            )}
          />
        </Datagrid>
      </List>
      <DialogForm
        open={!!jobCharacteristic}
        onCancel={() => setJobCharacteristic(undefined)}
        onSubmit={handleOnSubmit}
        loading={false}
        title="求人ラベルを編集する"
      >
        <TextInput
          source="name"
          label="resources.jobCharacteristics.fields.name"
          validate={required()}
          fullWidth
          defaultValue={jobCharacteristic?.name}
        />
      </DialogForm>
    </>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobCharacteristicsList
