import { HttpError } from "react-admin"
import type {
  CreateParams,
  DataProvider,
  DeleteParams,
  GetOneParams,
  GetListParams as RaGetListParams,
  GetManyParams as RaGetManyParams,
  GetManyReferenceParams as RaGetManyReferenceParams,
} from "react-admin"

import * as Sentry from "@sentry/react"

import client from "./apolloClient"
import {
  AdminAllFeatureTopicsDocument,
  AdminCreateCompanyDocument,
  AdminCreateCompanyStaffDocument,
  AdminCreateFeaturedJobDocument,
  AdminCreateJobCharacteristicDocument,
  AdminCreateMailNotificationDocument,
  AdminDeleteFeaturedJobDocument,
  AdminDeleteJobCharacteristicDocument,
  AdminDeleteJobSeekerDocument,
  AdminJobCharacteristicsByIdDocument,
  AdminListCompaniesDocument,
  AdminListFeatureTopicsDocument,
  AdminListFeaturedJobsDocument,
  AdminListJobCharacteristicsDocument,
  AdminListJobSeekerPrivacySettingsDocument,
  AdminListJobSeekersDocument,
  AdminListJobsByCompanyDocument,
  AdminListJobsDocument,
  AdminListMailNotificationsDocument,
  AdminShowCompaniesDocument,
  AdminShowCompanyDocument,
  AdminShowFeatureTopicDocument,
  AdminShowJobDocument,
  AdminShowJobSeekerDocument,
  AdminShowMailNotificationDocument,
  AdminUpdateCompanyDocument,
  AdminUpdateCompanyPlanDocument,
  AdminUpdateFeatureTopicDocument,
  AdminUpdateJobCharacteristicDocument,
  AdminUpdateJobDocument,
  AdminUpdateMailNotificationDocument,
} from "./generated/graphql"

import type {
  Company,
  CompanyStaff,
  CreateCompanyInput,
  CreateCompanyStaffInput,
  CreateFeaturedJobInput,
  CreateJobCharacteristicInput,
  FeatureTopic,
  FeaturedJob,
  Job,
  JobCharacteristic,
  JobSeeker,
  JobSeekerPrivacySetting,
  MailNotification,
  MailNotificationInput,
  SortOrder,
  UpdateCompanyInput,
  UpdateCompanyPlanInput,
  UpdateFeatureTopicInput,
  UpdateJobCharacteristicInput,
  UpdateJobInput,
} from "rikeimatch-graphql"
import type { AdminUpdateMailNotificationMutationVariables } from "./generated/graphql"

type ResourceType =
  | "jobs"
  | "jobSeekers"
  | "companies"
  | "featureTopics"
  | "featuredJobs"
  | "mailNotifications"
  | "jobCharacteristics"
  | "companyPlans"
  | "jobSeekerPrivacySettings"
  | "allFeatureTopics"
  | "companyStaffs"

type Resource =
  | Job
  | JobSeeker
  | Company
  | FeatureTopic
  | FeaturedJob
  | MailNotification
  | JobCharacteristic
  | JobSeekerPrivacySetting
  | CompanyStaff

type JobFilterParams = {
  q?: string
  title?: string
  description?: string
}

type CompanyFilterParams = {
  q?: string
  name?: string
}

type JobSeekerFilterParams = {
  q?: string
  educationHistoryName?: string
  educationHistoryGrade?: string
  educationHistoryFaculty?: string
  educationHistoryDepartment?: string
  educationHistoryMajor?: string
  prefecture?: string
}

type JobSeekerPrivacySettingFilterParams = {
  q?: string
}

type TopicFilterParams = {
  q?: string
  name?: string
}

type FeatureFilterParams = {
  featureTopicId: string
}

type GetListParams = RaGetListParams & {
  sort?: { field: string; order: SortOrder }
}

type GetManyParams = RaGetManyParams & {
  ids: string[]
}

type GetManyReferenceParams = RaGetManyReferenceParams & {
  id: string
}

const handleError = (result: unknown) => {
  if (import.meta.env.NODE_ENV === "production") {
    Sentry.captureMessage(JSON.stringify(result))
  } else {
    console.error(result)
  }

  return Promise.reject(new HttpError(result, 500, result))
}

// RAのCreateParamsだとPartialが付くので、FullCreateParamsを定義
type FullCreateParams<T> = {
  data: T
  meta?: unknown
}

const dataProvider = {
  getOne: async (resource: ResourceType, params: GetOneParams) => {
    switch (resource) {
      case "jobs": {
        const result = await client.query({
          query: AdminShowJobDocument,
          variables: params,
        })
        if (result.data?.job) {
          const data = result.data.job

          return { data }
        }

        return handleError(result)
      }

      case "companies": {
        const result = await client.query({
          query: AdminShowCompanyDocument,
          variables: params,
        })
        if (result.data?.company) {
          const data = result.data.company

          return { data }
        }

        return handleError(result)
      }

      case "jobSeekers": {
        const result = await client.query({
          query: AdminShowJobSeekerDocument,
          variables: params,
        })
        if (result.data?.jobSeeker) {
          const data = result.data.jobSeeker

          return { data }
        }

        return handleError(result)
      }

      case "featureTopics": {
        const result = await client.query({
          query: AdminShowFeatureTopicDocument,
          variables: params,
        })
        if (result.data?.featureTopic) {
          const data = result.data.featureTopic

          return { data }
        }

        return handleError(result)
      }

      case "mailNotifications": {
        const result = await client.query({
          query: AdminShowMailNotificationDocument,
          variables: params,
        })
        if (result.data?.mailNotification) {
          const data = result.data.mailNotification

          return { data }
        }

        return handleError(result)
      }

      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },

  getList: async (resource: ResourceType, params: GetListParams) => {
    switch (resource) {
      case "jobs": {
        const filters = params.filter as JobFilterParams

        const result = await client.query({
          query: AdminListJobsDocument,
          variables: {
            pagination: {
              page: params.pagination.page,
              perPage: params.pagination.perPage,
            },
            filters: Object.entries(filters).map(([key, value]) => ({
              field: key,
              value,
            })),
            sort: params.sort,
          },
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.jobs

          return { data, total }
        }

        return handleError(result)
      }

      case "companies": {
        const filters = params.filter as CompanyFilterParams

        const result = await client.query({
          query: AdminListCompaniesDocument,
          variables: {
            pagination: {
              page: params.pagination.page,
              perPage: params.pagination.perPage,
            },
            filters: Object.entries(filters).map(([key, value]) => ({
              field: key,
              value,
            })),
            sort: params.sort,
          },
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.companies

          return { data, total }
        }

        return handleError(result)
      }

      case "jobSeekers": {
        const filters = params.filter as JobSeekerFilterParams
        const result = await client.query({
          query: AdminListJobSeekersDocument,
          variables: {
            pagination: {
              page: params.pagination.page,
              perPage: params.pagination.perPage,
            },
            filters: Object.entries(filters).map(([key, value]) => ({
              field: key,
              value: value?.toString(),
            })),
            sort: params.sort,
          },
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.jobSeekers

          return { data, total }
        }

        return handleError(result)
      }

      case "jobSeekerPrivacySettings": {
        const filters = params.filter as JobSeekerPrivacySettingFilterParams
        const result = await client.query({
          query: AdminListJobSeekerPrivacySettingsDocument,
          variables: {
            pagination: {
              page: params.pagination.page,
              perPage: params.pagination.perPage,
            },
            filters: Object.entries(filters).map(([key, value]) => ({
              field: key,
              value: value?.toString(),
            })),
            sort: params.sort,
          },
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.jobSeekerPrivacySettings

          return { data, total }
        }

        return handleError(result)
      }

      case "featureTopics": {
        const filters = params.filter as TopicFilterParams
        const result = await client.query({
          query: AdminListFeatureTopicsDocument,
          variables: {
            pagination: {
              page: params.pagination.page,
              perPage: params.pagination.perPage,
            },
            filters: Object.entries(filters).map(([key, value]) => ({
              field: key,
              value,
            })),
            sort: params.sort,
          },
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.featureTopics

          return { data, total }
        }

        return handleError(result)
      }

      case "featuredJobs": {
        const filters = params.filter as FeatureFilterParams
        const result = await client.query({
          query: AdminListFeaturedJobsDocument,
          variables: {
            featureTopicId: filters.featureTopicId,
          },
        })
        if (result.data) {
          const data = result.data.featuredJobsByTopicId

          // TODO: paginationを使う
          return { data, total: data.length }
        }

        return handleError(result)
      }

      case "mailNotifications": {
        const result = await client.query({
          query: AdminListMailNotificationsDocument,
          variables: params,
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.mailNotifications

          return { data, total }
        }

        return handleError(result)
      }

      case "jobCharacteristics": {
        const result = await client.query({
          query: AdminListJobCharacteristicsDocument,
          variables: {
            pagination: {
              page: params.pagination.page,
              perPage: params.pagination.perPage,
            },
          },
        })
        if (result.data) {
          const {
            data,
            pagination: { total },
          } = result.data.jobCharacteristics

          return { data, total }
        }

        return handleError(result)
      }

      case "allFeatureTopics": {
        const result = await client.query({
          query: AdminAllFeatureTopicsDocument,
        })
        if (result.data) {
          const featureTopics = result.data.allFeatureTopics

          return { data: featureTopics, total: featureTopics.length }
        }

        return handleError(result)
      }

      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },

  getMany: async (resource: ResourceType, params: GetManyParams) => {
    switch (resource) {
      case "companies": {
        return client
          .query({
            query: AdminShowCompaniesDocument,
            variables: { ids: params.ids },
          })
          .then((result) => ({
            data: result.data.companiesById,
          }))
      }

      case "jobCharacteristics": {
        return client
          .query({
            query: AdminJobCharacteristicsByIdDocument,
            variables: { ids: params.ids },
          })
          .then((result) => ({
            data: result.data.jobCharacteristicsById,
          }))
      }

      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },

  getManyReference: async (resource: ResourceType, params: GetManyReferenceParams) => {
    switch (resource) {
      case "jobs": {
        return client
          .query({
            query: AdminListJobsByCompanyDocument,
            variables: { companyId: params.id },
          })
          .then(({ data: { jobsByCompany } }) => ({
            data: jobsByCompany?.data,
            total: jobsByCompany?.pagination?.total || 0,
          }))
      }

      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },

  update: async (resource: ResourceType, params) => {
    switch (resource) {
      case "jobs": {
        const input = params.data as UpdateJobInput

        const result = await client.mutate({
          mutation: AdminUpdateJobDocument,
          variables: { input },
        })
        if (result.data) {
          const data = result.data.updateJob

          return { data }
        }

        return handleError(result)
      }

      case "companies": {
        switch (params.meta.resource) {
          case "companyPlans": {
            const input = params.data as UpdateCompanyPlanInput

            const result = await client.mutate({
              mutation: AdminUpdateCompanyPlanDocument,
              variables: { input },
            })
            if (result.data) {
              const data = result.data.updateCompanyPlan

              return { data }
            }

            return handleError(result)
          }
          case "companies": {
            const { ...input } = params.data as UpdateCompanyInput
            const result = await client.mutate({
              mutation: AdminUpdateCompanyDocument,
              variables: { input },
            })
            if (result.data) {
              const data = result.data.adminUpdateCompany

              return { data }
            }

            return handleError(result)
          }
          default:
            throw new HttpError("Unsupported", 500, "Unsupported")
        }
      }

      case "featureTopics": {
        const rest = params.data as UpdateFeatureTopicInput

        const result = await client.mutate({
          mutation: AdminUpdateFeatureTopicDocument,
          variables: { input: rest },
        })
        if (result.data) {
          const data = result.data.updateFeatureTopic

          return { data }
        }

        return handleError(result)
      }

      case "mailNotifications": {
        const { id, input } = params.data as AdminUpdateMailNotificationMutationVariables

        const result = await client.mutate({
          mutation: AdminUpdateMailNotificationDocument,
          variables: { id, input },
        })
        if (result.data) {
          const data = result.data.updateMailNotification

          return { data }
        }

        return handleError(result)
      }

      case "jobCharacteristics": {
        const input = params.data as UpdateJobCharacteristicInput

        const result = await client.mutate({
          mutation: AdminUpdateJobCharacteristicDocument,
          variables: { input },
        })
        if (result.data) {
          const data = result.data.updateJobCharacteristic

          return { data }
        }

        return handleError(result)
      }

      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },

  create: async (resource: ResourceType, params) => {
    switch (resource) {
      case "companies": {
        const result = await client.mutate({
          mutation: AdminCreateCompanyDocument,
          variables: {
            input: (params as FullCreateParams<CreateCompanyInput>).data,
          },
        })
        if (result.data) {
          const data = result.data.createCompany

          return { data }
        }

        return handleError(result)
      }

      case "companyStaffs": {
        const result = await client.mutate({
          mutation: AdminCreateCompanyStaffDocument,
          variables: {
            input: (params as FullCreateParams<CreateCompanyStaffInput>).data,
          },
        })
        if (result.data) {
          const data = result.data.createCompanyStaff

          return { data }
        }

        return handleError(result)
      }

      case "featuredJobs": {
        const result = await client.mutate({
          mutation: AdminCreateFeaturedJobDocument,
          variables: {
            input: (params as FullCreateParams<CreateFeaturedJobInput>).data,
          },
        })
        if (result.data) {
          const data = result.data.createFeaturedJob

          return { data }
        }

        return handleError(result)
      }

      case "mailNotifications": {
        const result = await client.mutate({
          mutation: AdminCreateMailNotificationDocument,
          variables: {
            input: (params as CreateParams<MailNotificationInput>).data,
          },
        })
        if (result.data) {
          const data = result.data.createMailNotification

          return { data }
        }

        return handleError(result)
      }

      case "jobCharacteristics": {
        const result = await client.mutate({
          mutation: AdminCreateJobCharacteristicDocument,
          variables: {
            input: (params as FullCreateParams<CreateJobCharacteristicInput>).data,
          },
        })
        if (result.data) {
          const data = result.data.createJobCharacteristic

          return { data }
        }

        return handleError(result)
      }

      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },

  delete: async (resource: ResourceType, params: DeleteParams<Resource>) => {
    switch (resource) {
      case "jobSeekers": {
        const result = await client.mutate({
          mutation: AdminDeleteJobSeekerDocument,
          variables: { id: params.id },
        })
        if (result.data) {
          const data = result.data.deleteJobSeeker

          return { data }
        }

        return handleError(result)
      }

      case "featuredJobs": {
        const result = await client.mutate({
          mutation: AdminDeleteFeaturedJobDocument,
          variables: { id: params.id },
        })
        if (result.data) {
          const data = result.data.deleteFeaturedJob

          return { data }
        }

        return handleError(result)
      }

      case "jobCharacteristics": {
        const result = await client.mutate({
          mutation: AdminDeleteJobCharacteristicDocument,
          variables: { id: params.id },
        })
        if (result.data) {
          const data = result.data.deleteJobCharacteristic

          return { data }
        }

        return handleError(result)
      }
      default:
        return Promise.reject(new HttpError("Unsupported", 500, "Unsupported"))
    }
  },
} as DataProvider

// biome-ignore lint/style/noDefaultExport: ...
export default dataProvider
