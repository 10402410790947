import type React from "react"
import {
  Create,
  DateInput,
  NumberInput,
  SimpleForm,
  TextInput,
  minLength,
  required,
} from "react-admin"

const CompanyCreate: React.FC = () => {
  const defaultExpiresAt = () => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + 6)

    return currentDate
  }

  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" validate={[required(), minLength(5)]} helperText="5字以上" />
        <TextInput source="email" type="email" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput source="firstName" validate={[required()]} />
        <NumberInput source="likeCount" defaultValue={50} validate={[required()]} />
        <DateInput
          source="likeExpiresOn"
          defaultValue={defaultExpiresAt()}
          validate={[required()]}
        />
        <NumberInput source="scoutCount" defaultValue={50} validate={[required()]} />
        <DateInput
          source="scoutExpiresOn"
          defaultValue={defaultExpiresAt()}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default CompanyCreate
