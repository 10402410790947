import type React from "react"
import { SaveButton, Toolbar } from "react-admin"

import { Stack } from "@mui/material"

const ToolbarWithoutDelete: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Toolbar>
    <Stack direction="row" spacing={3}>
      <SaveButton />
      {children}
    </Stack>
  </Toolbar>
)

ToolbarWithoutDelete.defaultProps = {
  children: undefined,
}

// biome-ignore lint/style/noDefaultExport: ...
export default ToolbarWithoutDelete
