import { gql, useQuery } from "@apollo/client"

import type { AdminListInterviewTemplatesQuery } from "~/generated/graphql"

const LIST_INTERVIEW_TEMPLATES = gql`
  query ListInterviewTemplates {
    interviewTemplates {
      id
      question
      answer
      evaluation
      updatedAt
    }
  }
`

const useInterviewTemplates = () => {
  const { data, error, loading } =
    useQuery<AdminListInterviewTemplatesQuery>(LIST_INTERVIEW_TEMPLATES)
  const interviewTemplates = data?.interviewTemplates

  return {
    interviewTemplates,
    error,
    loading,
  }
}

// biome-ignore lint/style/noDefaultExport: ...
export default useInterviewTemplates
