import React from "react"
import { useListSortContext } from "react-admin"

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import SortIcon from "@mui/icons-material/Sort"
import { Button, Menu, MenuItem } from "@mui/material"

const OPTIONS = [
  {
    value: "users.lastAuthenticatedAt",
    label: "ログインが新しい順",
  },
  {
    value: "jobSeekers.createdAt",
    label: "登録が新しい順",
  },
]

const DEFAULT_SORT_BY = { field: OPTIONS[0].value, order: "DESC" }

const JobSeekerSortControl = () => {
  const { sort, setSort } = useListSortContext() || {}

  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>()
  if (!sort) return null

  const handleClick = (event: React.MouseEvent<EventTarget & HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(undefined)
  }
  const handleChangeSort = (event: React.MouseEvent<EventTarget & HTMLElement>) => {
    const field = event.currentTarget.dataset.sort
    if (field) {
      setSort({ field, order: "DESC" })
    }
    setAnchorEl(undefined)
  }

  const buttonLabel = OPTIONS.find((option) => option.value === sort.field)?.label

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        startIcon={<SortIcon />}
        endIcon={<ArrowDropDownIcon />}
        size="small"
      >
        {buttonLabel}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {OPTIONS.map((option) => (
          <MenuItem
            disabled={option.value === sort.field}
            onClick={handleChangeSort}
            data-sort={option.value}
            key={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export { JobSeekerSortControl, DEFAULT_SORT_BY as JOB_SEEKER_DEFAULT_SORT_BY }
