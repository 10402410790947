import type React from "react"
import { Datagrid, EditButton, List, SearchInput, TextField } from "react-admin"

import { JobStatusFieldForJobList } from "common-components-ra"

const jobFilter = [<SearchInput key="jobFilter" source="q" alwaysOn />]

const JobList: React.FC = () => (
  <List resource="jobs" filters={jobFilter} sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <JobStatusFieldForJobList />
      <TextField source="company.name" label="resources.companies.name" />
      <EditButton />
    </Datagrid>
  </List>
)

// biome-ignore lint/style/noDefaultExport: ...
export default JobList
