import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import { ISODateTimeToDateString } from "common-utils"

import useChatGptThreads from "~/hooks/useChatGptThreads"

type PropsType = {
  handleSelectThread: (id: string) => void
}

const ChatGptThreadListThreadsMenu = ({ handleSelectThread }: PropsType) => {
  const { chatGptThreads } = useChatGptThreads()

  return (
    <Paper sx={{ minWidth: 220, maxWidth: 220, height: "100%", overflow: "auto" }}>
      <MenuList sx={{}}>
        {chatGptThreads?.map((thread) => (
          <MenuItem
            key={thread.id}
            onClick={() => handleSelectThread(thread.id)}
            sx={{ display: "block" }}
          >
            <ListItemText>{thread.jobSeeker?.fullName}</ListItemText>
            <ListItemText>{ISODateTimeToDateString(thread.createdAt)}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default ChatGptThreadListThreadsMenu
