import { Button, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

import useInterviewTemplates from "~/hooks/useInterviewTemplates"

import InterviewTemplateListWrapCell from "../organisms/InterviewTemplateListWrapCell"

import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import type { InterviewTemplate } from "rikeimatch-graphql"

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "question",
    headerName: "質問",
    flex: 1,
    renderCell: (params: GridRenderCellParams<InterviewTemplate>) => (
      <InterviewTemplateListWrapCell value={params.row.question} />
    ),
  },
  {
    field: "answer",
    headerName: "回答",
    flex: 1,
    renderCell: (params: GridRenderCellParams<InterviewTemplate>) => (
      <InterviewTemplateListWrapCell value={params.row.answer} />
    ),
  },
  {
    field: "evaluation",
    headerName: "評価",
    flex: 1,
    renderCell: (params: GridRenderCellParams<InterviewTemplate>) => (
      <InterviewTemplateListWrapCell value={params.row.evaluation} />
    ),
  },
  { field: "updatedAt", headerName: "更新日", width: 150 },
]

const InterviewTemplateList = () => {
  const { interviewTemplates } = useInterviewTemplates()

  const rows =
    interviewTemplates?.map((template) => ({
      id: template.id,
      question: template.question,
      answer: template.answer,
      evaluation: template.evaluation,
      updatedAt: template.updatedAt,
    })) || []

  return (
    <Stack sx={{ height: "100%", display: "flex", flexDirection: "column", p: 3 }} spacing={1}>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6">AI面接データセット</Typography>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" sx={{ textTransform: "none" }}>
            Fine Tuning
          </Button>
          <Button variant="contained">新規作成</Button>
        </Stack>
      </Stack>
      <DataGrid sx={{ flexGrow: 1 }} rows={rows} columns={columns} rowHeight={80} />
    </Stack>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default InterviewTemplateList
