import type { FC } from "react"
import {
  DateField,
  DeleteButton,
  EmailField,
  FunctionField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from "react-admin"

import { Chip, Typography } from "@mui/material"
import {
  EducationGradeField,
  JobSeekerPrefectureField,
  JobSeekerSocialAccountField,
  JobSeekerStatusField,
} from "common-components-ra"

import type { JobSeeker } from "rikeimatch-graphql"

const JobSeekerTitle = () => {
  const { fullName } = useRecordContext() || {}

  return <span>{fullName}さん</span>
}

const JobSeekerShow: FC = () => {
  const t = useTranslate()

  return (
    <Show title={<JobSeekerTitle />}>
      <TabbedShowLayout spacing={3}>
        <Tab label="概要">
          <TextField source="id" />
          <TextField source="fullName" />
          <EmailField source="email" />
          <JobSeekerPrefectureField source="address" />
          <JobSeekerStatusField source="status" />
          <DeleteButton mutationMode="pessimistic" />
        </Tab>
        <Tab label="学歴">
          <TextField
            source="educationHistory.name"
            label="resources.educationHistory.fields.name"
          />
          <EducationGradeField
            source="educationHistory.grade"
            label="resources.educationHistory.fields.grade"
          />
          <TextField
            source="educationHistory.faculty"
            label="resources.educationHistory.fields.faculty"
          />
          <TextField
            source="educationHistory.department"
            label="resources.educationHistory.fields.department"
          />
          <TextField
            source="educationHistory.major"
            label="resources.educationHistory.fields.major"
          />

          <FunctionField
            label="resources.jobSeekers.fields.studyKeywords"
            render={(jobSeeker: JobSeeker) =>
              jobSeeker.studyKeywords.length > 0 ? (
                jobSeeker.studyKeywords.map((studyKeyword) => (
                  <Chip key={studyKeyword} label={studyKeyword} sx={{ marginRight: "5px" }} />
                ))
              ) : (
                <Typography component="span" variant="body2">
                  未入力
                </Typography>
              )
            }
          />
          <TextField
            source="studyDetail"
            emptyText="未入力"
            sx={{
              whiteSpace: "pre-line",
              display: "inline-block",
            }}
          />
          <TextField
            source="educationHistory.researchSubject"
            label="resources.educationHistory.fields.researchSubject"
            emptyText="未入力"
          />
          <TextField
            source="educationHistory.researchDetail"
            label="resources.educationHistory.fields.researchDetail"
            emptyText="未入力"
            sx={{
              whiteSpace: "pre-line",
              display: "inline-block",
            }}
          />
          <TextField source="focusSummary" emptyText="未入力" />
          <TextField
            source="focusDetail"
            emptyText="未入力"
            sx={{
              whiteSpace: "pre-line",
              display: "inline-block",
            }}
          />
          <TextField
            source="thingsChangedAfterCovid"
            emptyText="未入力"
            sx={{
              whiteSpace: "pre-line",
              display: "inline-block",
            }}
          />
          <DateField
            source="educationHistory.graduatedOn"
            label="resources.educationHistory.fields.graduatedOn"
            locales="ja-JP"
            options={{
              dateStyle: "medium",
            }}
          />
          <FunctionField
            label="resources.jobSeekers.fields.certifications"
            render={(jobSeeker: JobSeeker) =>
              jobSeeker.certifications?.length > 0 ? (
                jobSeeker.certifications.map((certification, index) => (
                  <Chip
                    key={`${certification}-${
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      index
                    }`}
                    label={certification}
                    sx={{ marginRight: "5px" }}
                  />
                ))
              ) : (
                <Typography component="span" variant="body2">
                  未入力
                </Typography>
              )
            }
          />
          <NumberField source="toeicScore" emptyText="未入力" />
          <JobSeekerSocialAccountField source="socialAccounts" />
        </Tab>
        <Tab label="希望条件">
          <FunctionField
            label="resources.jobSeekers.fields.desiredJobTypes"
            render={(jobSeeker: JobSeeker) =>
              jobSeeker.desiredJobTypes?.length > 0 ? (
                jobSeeker.desiredJobTypes.map((jobType, index) => (
                  <Chip
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={`${jobType}-${index}`}
                    label={jobType}
                    sx={{ marginRight: "5px" }}
                  />
                ))
              ) : (
                <Typography component="span" variant="body2">
                  未入力
                </Typography>
              )
            }
          />
          <FunctionField
            label="resources.jobSeekers.fields.desiredWorkLocations"
            render={(jobSeeker: JobSeeker) =>
              jobSeeker.desiredWorkLocations?.length > 0 ? (
                jobSeeker.desiredWorkLocations.map((workLocation, index) => (
                  <Chip
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={`${workLocation}-${index}`}
                    label={t(`resources.prefecturesForDesiredWork.${workLocation}`)}
                    sx={{ marginRight: "5px" }}
                  />
                ))
              ) : (
                <Typography component="span" variant="body2">
                  未入力
                </Typography>
              )
            }
          />
          <FunctionField
            label="resources.jobSeekers.fields.desiredCompanySizes"
            render={(jobSeeker: JobSeeker) =>
              jobSeeker.desiredCompanySizes?.length > 0 ? (
                jobSeeker.desiredCompanySizes.map((companySize, index) => (
                  <Chip
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={`${companySize}-${index}`}
                    label={t(`resources.jobSeekers.desiredCompanySizes.${companySize}`)}
                    sx={{ marginRight: "5px" }}
                  />
                ))
              ) : (
                <Typography component="span" variant="body2">
                  未入力
                </Typography>
              )
            }
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobSeekerShow
