import { SelectArrayInput, useRecordContext } from "react-admin"

import { i18n, objectToOptions } from "common-utils"

import type { Company } from "rikeimatch-graphql"

const CompanyLocationsInput = () => {
  const { locations } = useRecordContext<Company>()

  const choices = objectToOptions(i18n.companyLocations)

  return (
    <SelectArrayInput
      source="locations"
      alwaysOn
      label="resources.companies.fields.locations"
      sx={{ minWidth: "140px" }}
      defaultValue={locations}
      choices={choices}
      validate={(value: string[] | undefined) => {
        if (value?.length === 0) {
          return "勤務地を1つ以上選択してください"
        }

        return null
      }}
    />
  )
}

// biome-ignore lint/style/noDefaultExport: ...
export default CompanyLocationsInput
