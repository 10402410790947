import type React from "react"
import { Datagrid, DateField, FunctionField, List, TextField, useRecordContext } from "react-admin"

import { PreviewTextField } from "~/components/molecules"
import { prettifyErbVars } from "~/utilities/transformErb"

import type { MailNotification } from "rikeimatch-graphql"

const MailNotificationTitle = () => {
  const { subject } = useRecordContext() || {}

  return <span>{subject ?? "メール通知"}</span>
}

const MailNotificationList: React.FC = () => (
  <List title={<MailNotificationTitle />} resource="mailNotifications">
    <Datagrid rowClick="edit">
      <TextField source="subject" />
      <FunctionField
        source="body"
        render={(mail: MailNotification) => (
          <PreviewTextField text={prettifyErbVars(mail.body)} maxChars={50} />
        )}
      />
      <DateField
        source="sendAt"
        showTime
        locales="ja-JP"
        options={{
          dateStyle: "medium",
        }}
      />
    </Datagrid>
  </List>
)

// biome-ignore lint/style/noDefaultExport: ...
export default MailNotificationList
