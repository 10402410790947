import type React from "react"

import { Button } from "@mui/material"

type Props = {
  href: string
}

const ButtonLink: React.FC<React.PropsWithChildren<Props>> = ({ href, children }) => (
  <Button variant="outlined" href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </Button>
)

// biome-ignore lint/style/noDefaultExport: ...
export default ButtonLink
